<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="创建时间：" label-width="100">
                <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="用户id" label-width="100">
                <el-input v-model="searchForm.userId" placeholder="用户id" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="用户昵称" label-width="100">
                <el-input v-model="searchForm.nickName" placeholder="用户昵称" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="用户状态" label-width="100">
                <el-select v-model="searchForm.status" clearable placeholder="选择用户状态">
                    <el-option label="无效" value="0"></el-option>
                    <el-option label="有效" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="身份" label-width="100">
                <el-select v-model="searchForm.identity" clearable placeholder="选择身份">
                    <el-option label="货主" :value="2"></el-option>
                    <el-option label="司机" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <!--            <el-form-item label="是否认证" label-width="100">-->
            <!--                <el-select v-model="searchForm.isAuthentication" clearable placeholder="选择是否认证">-->
            <!--                    <el-option label="未认证" :value="0"></el-option>-->
            <!--                    <el-option label="认证中" :value="2"></el-option>-->
            <!--                    <el-option label="已认证" :value="1"></el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item>
                <el-button @click="getUserBase" type="success">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column label="用户id" align="center" prop="userId" width="100">
            </el-table-column>
            <el-table-column label="用户昵称" align="center" prop="nickName" min-width="300">
            </el-table-column>
            <el-table-column label="用户头像" align="center" width="100">
                <template slot-scope="scope">
                    <el-avatar size="small" :src="scope.row.avatarUrl"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="phone" width="130">
            </el-table-column>
            <el-table-column label="真实姓名" align="center" prop="fullName" width="130">
            </el-table-column>
            <el-table-column prop="status" align="center" label="用户状态" width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.status === 0" type="info">封号</el-tag>
                    <el-tag size="small" v-else-if="scope.row.status === 1" type="success">有效</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="identity" align="center" label="身份" width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.identity === 2" type="info">货主</el-tag>
                    <el-tag size="small" v-else-if="scope.row.identity === 3" type="success">司机</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="isAuthentication" align="center" label="是否认证" width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isAuthentication === 0" type="info">未认证</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isAuthentication === 1" type="success">已认证</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isAuthentication === 2" type="success">认证中</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" width="260" label="创建时间">
            </el-table-column>
            <el-table-column prop="icon" align="center" width="160" fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="addUpdateCouponsButton(scope.row.userId, null)">审核</el-button>
                    <el-button type="primary" plain
                        @click="addUpdateCouponsButton(scope.row.userId, 'detail')">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
            :page-size="size" :total="total">
        </el-pagination>
        <add-certifiion ref="addCertifiion" v-if="addCertifiionvisible" @refreshDataList="getUserBase"></add-certifiion>
        <AddCertifiiondetail ref="addCertifiiondetail" v-if="detailvisible"></AddCertifiiondetail>
    </div>
</template>

<script>
import AddCertifiion from "./AddCertifiion";
import AddCertifiiondetail from './AddCertifiiondetail.vue'
export default {
    name: "CertificationAudit",
    components: {
        AddCertifiion,AddCertifiiondetail
    },
    data() {
        return {
            searchForm: {},
            delBtlStatu: true,
            addCertifiionvisible: false,
            tableData: [],
            multipleSelection: [],
            detailvisible:false,
            total: 0,
            size: 10,
            current: 1,
        }
    },
    created() {
        this.getUserBase();
    },
    mounted() {
        // 判断浏览器是否是火狐
        if (navigator.userAgent.indexOf("Firefox") > 0) {
            this.isFirefox = true;
        }
    },
    methods: {
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.delBtlStatu = val.length == 0
        },

        handleSizeChange(val) {
            this.size = val
            this.getUserBase()
        },
        handleCurrentChange(val) {
            this.current = val
            this.getUserBase()
        },
        addUpdateCouponsButton(id, type) {
            if (!type) {
                this.addCertifiionvisible = true;
                this.$nextTick(() => {
                    this.$refs.addCertifiion.init(id);
                })
            } else {
                this.detailvisible = true;
                this.$nextTick(() => {
                    this.$refs.addCertifiiondetail.init(id);
                })
            }
        },

        //获取标签类型列表
        getUserBase() {
            var startTime = '';
            var endTime = '';
            if (this.searchForm.createTime != undefined) {
                startTime = this.searchForm.createTime[0];
                endTime = this.searchForm.createTime[1];
            }
            this.searchForm.startTime = startTime
            this.searchForm.endTime = endTime;
            this.searchForm.pageNo = this.current
            this.searchForm.pageSize = this.size
            this.searchForm.isAuthentication = 2
            this.$axios.post("/admin/userBase/getUserBase", this.searchForm).then(res => {
                this.tableData = res.data.records
                this.size = res.data.size
                this.current = res.data.pages
                this.total = res.data.total
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>
