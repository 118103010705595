<template>
    <el-dialog title="认证审核" :visible.sync="dialogVisible" width="800" :close-on-click-modal="false" top="3vh"
        :before-close="handleClose">

        <el-form :model="editForm" ref="editForm" label-width="150px" class="demo-editForm">
            <el-divider content-position="center">认证明细</el-divider>
            <el-row>
                <el-col :span="11" v-if="editForm.name">
                    <el-form-item label="姓名：" prop="name">
                        <div>{{ editForm.name }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.idNubmer">
                    <el-form-item label="身份证号：" prop="idNubmer">
                        <div>{{ editForm.idNubmer }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.phone">
                    <el-form-item label="手机号：" prop="phone">
                        <div>{{ editForm.phone }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.createTime">
                    <el-form-item label="认证时间：" prop="createTime">
                        <div>{{ editForm.createTime }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.method">
                    <el-form-item label="认证方式：" prop="method">
                        <div v-if="editForm.method == 1"> 企业认证</div>
                        <div v-if="editForm.method == 2"> 个人认证</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.identity">
                    <el-form-item label="认证身份：" prop="identity">
                        <div v-if="editForm.identity == 2">货主</div>
                        <div v-if="editForm.identity == 3">司机</div>

                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.licensPlateNumber">

                    <el-form-item label="车牌号：" prop="licensPlateNumber">
                        <div>{{ editForm.licensPlateNumber }}</div>

                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.hangLicensePlate">
                    <el-form-item label="挂车车牌号：" prop="hangLicensePlate">
                        <div>{{ editForm.hangLicensePlate }}</div>

                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.colorValue">
                    <el-form-item label="车牌颜色：" prop="colorValue">
                        <div>{{ editForm.colorValue }}</div>

                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.newEnergyCart">
                    <el-form-item label="是否新能源：" prop="newEnergyCart">
                        <div v-if="editForm.newEnergyCart == 0">否</div>
                        <div v-if="editForm.newEnergyCart == 1">是</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.drivingLicenseExpirationTime">
                    <el-form-item label="行驶证到期时间：" prop="drivingLicenseExpirationTime">
                        <div>{{ editForm.drivingLicenseExpirationTime }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="editForm.driverLicenseExpirationTime">
                    <el-form-item label="驾驶证到期时间：" prop="driverLicenseExpirationTime">
                        <div>{{ editForm.driverLicenseExpirationTime }}</div>

                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList1.length > 0">
                    <el-form-item label="身份证国徽面：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList1" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList.length > 0">
                    <el-form-item label="身份证头像面：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>

                <el-col :span="11" v-if="fileList2.length > 0">
                    <el-form-item label="营业执照：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList2" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList3.length > 0">
                    <el-form-item label="驾驶证：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList3" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList4.length > 0">
                    <el-form-item label="行驶证：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList4" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList5.length > 0">
                    <el-form-item label="道路运输经营许可证：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList5" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
                <el-col :span="11" v-if="fileList6.length > 0">
                    <el-form-item label="道路运输从业资格证：" prop="amount">
                        <VploadImgTwo v-bind:file-list="fileList6" :limit="1" :is-disabled="true" :delremove="false"></VploadImgTwo>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="center">认证审核</el-divider>
            <el-row>
                <el-form-item label="审核状态">
                    <el-radio-group v-model="IsOpt">
                        <el-radio label="1">通过</el-radio>
                        <el-radio label="2">不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-row>
            <el-row v-if="IsOpt == 2">
                <el-form-item label="拒绝原因">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="rejectionReason">
                    </el-input>
                </el-form-item>
            </el-row>
            <el-form-item>
                <el-button type="primary" @click="saveCertification('editForm')">提交</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
export default {
    name: "AddCertifiion",
    components: {
        VploadImgTwo,
    },
    data() {
        return {
            IsOpt: '1',
            rejectionReason: '',
            fileList: [],
            fileList1: [],
            fileList2: [],
            fileList3: [],
            fileList4: [],
            fileList5: [],
            fileList6: [],
            editForm: {},
            dialogVisible: false,
        }
    },
    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
            this.fileList = []
            this.fileList1 = []
            this.fileList2 = []
            this.fileList3 = []
            this.fileList4 = []
            this.fileList5 = []
            this.fileList6 = []
        },
        handleClose() {
            this.rejectionReason = ''
            this.IsOpt = '1'
            this.resetForm('editForm')
        },
        init(id) {
            this.dialogVisible = true;
            if (id != null && id != '' && id > 0) {
                this.$axios.post('/admin/userBase/getCertification', { userId: id }).then(res => {
                    this.editForm = res.data
                    var termValidity = [];
                    if (res.data) {
                        if (res.data.front) {
                            var filess = { url: res.data.front }
                            this.fileList.push(filess)
                        }
                        if (res.data.negative) {
                            var filess = { url: res.data.negative }
                            this.fileList1.push(filess)
                        }
                        if (res.data.businessLicense) {
                            var filess = { url: res.data.businessLicense }
                            this.fileList2.push(filess)
                        }
                        if (res.data.driverLicense) {
                            var filess = { url: res.data.driverLicense }
                            this.fileList3.push(filess)
                        }
                        if (res.data.drivingLicense) {
                            var filess = { url: res.data.drivingLicense }
                            this.fileList4.push(filess)
                        }
                        if (res.data.roadTransportBusinessLicensePath) {
                            var filess = { url: res.data.roadTransportBusinessLicensePath }
                            this.fileList5.push(filess)
                        }
                        if (res.data.roadTransportQualificationCertificatePath) {
                            var filess = { url: res.data.roadTransportQualificationCertificatePath }
                            this.fileList6.push(filess)
                        }
                    }
                })
            }

        },
        saveCertification(formName) {
            if (this.IsOpt == 1) {
                this.$axios.post('/admin/userBase/saveCertification', { userId: this.editForm.userId })
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: '恭喜你，操作成功',
                            type: 'success',
                            onClose: () => {
                                this.$emit('refreshDataList')
                            }
                        });
                        this.dialogVisible = false
                        this.resetForm(formName)
                    })
            }
            if (this.IsOpt == 2) {
                if (this.rejectionReason == '' || this.rejectionReason == null) {
                    this.$message('请输入拒绝原因');
                    return false
                }
                this.$axios.post('/admin/userBase/refuseCertification', { userId: this.editForm.userId, rejectionReason: this.rejectionReason })
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: '恭喜你，操作成功',
                            type: 'success',
                            onClose: () => {
                                this.$emit('refreshDataList')
                            }
                        });
                        this.dialogVisible = false
                        this.resetForm(formName)
                    })
            }
        },
    }
}
</script>

<style scoped></style>
